
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import CreateEditDialog from '@/components/dialog/CreateEditDialog.vue';
import TagForm from '@/components/tag/TagForm.vue';
import useTag from '@/components/tag/tag';

export default defineComponent({
  name: 'CreateEditTagDialog',
  components: {
    CreateEditDialog,
    TagForm,
  },
  setup() {
    // store
    const store = useStore();

    return {
      ...useTag(store),
    };
  },
});
