
import {defineComponent} from 'vue';
import CreateTagDialog from '@/components/tag/CreateEditTagDialog.vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useTagList from '@/views/tag/list/tagList';

export default defineComponent({
  name: 'TagList',
  components: {
    ListLayout,
    CreateTagDialog,
  },
  setup() {
    const {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    } = useTagList();

    return {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    };
  },
});
